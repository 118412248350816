import { FunctionComponent } from 'react';
import { Select } from '@mantine/core';

type MonthSelectProps = {
  onSelect: (service: string) => void
}

const MONTHS = () => {
  // const toMonth = new Date().getMonth();
  const months = [...Array(12).fill(true)].map(
    (_, i) => ({
      value: new Date(0, i).toLocaleString('en', { month: 'short' }).toLocaleLowerCase(),
      label: new Date(0, i).toLocaleString('en', { month: 'long' }),
      disabled: false
    })
  );

  return months;
}

const MonthSelect: FunctionComponent<MonthSelectProps> = ({
  onSelect
}) => {
  return (
    <Select
      label="Month"
      placeholder="Select..."
      data={MONTHS()}
      onChange={(v: string) => onSelect(v)}
    />
  )
}

export default MonthSelect