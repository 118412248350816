import { FunctionComponent } from 'react';
import { Select } from '@mantine/core';

type ProductSelectProps = {
  onSelect: (service: string) => void
}

const SERVICES = [
  "GameOn",
  "Glam Galore",
  "Indico btips",
  "Indico career tips",
  "Indico Dating tips",
  "Indico health tips",
  "Indico Money",
  "Indico stips",
  "Indico Success",
  "Indico Wisdom",
  "Success Power",

  "Infotainment Zone Education",
  "Infotainment Zone Health",
  "Infotainment Zone Jokes",
  "Infotainment Zone Lifestyle",
  "Infotainment Zone Love",
];

const options = SERVICES.map((opt) => ({ value: opt, label: opt }))

const ProductSelect: FunctionComponent<ProductSelectProps> = ({
  onSelect
}) => {
  return (
    <Select
      label="Products"
      placeholder="Select..."
      data={[
        { value: 'all', label: 'All products' },
        ...options
      ]}
      onChange={(v: string) => onSelect(v)}
    />
  )
}

export default ProductSelect