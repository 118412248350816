import { useState } from 'react';
import { 
  Container,
  Grid,
  Box,
  Group,
  Title,
  Button,
  Text,
  // Select,
  Card, LoadingOverlay, ScrollArea
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import MonthSelect from '../../components/MonthSelect';
import ProductSelect from '../../components/ProductSelect';
import { useStyles } from './useStyles';
import EmptyResponse from '../../components/EmptyResponse';


const Dashboard = () => {
  const { classes } = useStyles();
  const [service, setService] = useState('');
  const [month, setMonth] = useState('');

  const { data, fetchStatus } = useQuery([service, month], async () => {
    const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8090' : 'https://report.mtn.mobivs.com/serve';
    const res = await fetch(`${url}?service=${service}&month=${month}`, {
      method: 'GET'
    });

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    return await res.json().then(data => data);
  }, {
    enabled: month !== '' && service !== '',
    keepPreviousData: true
  });

  return (
    <Container py="lg">
      <Grid>
        <Grid.Col span={4}>
          <Title>MTN Reports</Title>

          <Text color="dimmed" size="sm">
            Monthly Subscriber Report.
          </Text>
        </Grid.Col>

        <Grid.Col span={8}>
        <Box>
          <Group align="end" mb="lg">
            <ProductSelect onSelect={setService} />
            {/* <Select
              label="Year"
              placeholder="Select..."
              data={["2022", "2023"]}
              // onChange={(v: string) => onSelect(v)}
              style={{width: '100px'}}
            /> */}

            <MonthSelect onSelect={setMonth} />
            {/* <Button>GO</Button> */}
          </Group>

          <Card withBorder radius="md" p={0} className={classes.card}>
            <ScrollArea.Autosize maxHeight={data?.data.length > 0 ? 92 : 500}>
              <Box p="xl">
              {fetchStatus === 'fetching' ? (
                <div style={{height: '200px'}}>
                  <LoadingOverlay visible={true} overlayBlur={2} />
                </div>
              ) : data?.data.length > 0 ? data?.data.map((file: any, i: number) => <Group 
                key={i} position="apart" className={classes.item} noWrap spacing="xl"
              >
                <div>
                  <Text>{file.filename}</Text>
                  <Text size="xs" color="dimmed">
                    {file.filesize}
                  </Text>
                </div>
                <Button component='a' href={file.url} download target="_blank">Download</Button>
              </Group>
              ) : (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <EmptyResponse />
                  <Text mt="lg">No record found!</Text>
                </div>
            )}</Box>
            </ScrollArea.Autosize>
          </Card>
        </Box>
        </Grid.Col>
      </Grid>
    </Container>
  )
}

export default Dashboard