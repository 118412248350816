import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Login from "./Auth/Login";
import Dashboard from "./Dashboard";

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: 'dashboard',
    element: <Dashboard />
  }
])

const Router = () => {
  return <RouterProvider router={routes} />
}

export default Router;