import {ReactComponent as Empty} from '../assets/empty.svg'

const EmptyResponse = () => {
  return (
    <div>
      <div className="">
        <Empty />
      </div>
    </div>
  )
}

export default EmptyResponse