import { useState } from 'react';
import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../constants';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setError] = useState<{username: string, password: string}>({
    username: '',
    password: ''
  });

  const handleSubmit = () => {
    const form = new FormData();
    setError({
      username: '',
      password: ''
    });

    if(username === '') {
      setError(prev => ({...prev, username: 'Enter username'}))
    }
    if(password === '') {
      setError(prev => ({...prev, password: 'Enter password'}))
    }

    form.append('username', username);
    form.append('password', password);

    fetch(`${baseUrl}/auth.php`, {
      method: 'POST',
      body: form,
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        navigate('/dashboard');
      }
    })
  }

  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Welcome back!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput 
          label="Username" 
          placeholder="Your username" 
          required value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={errors.username}
        />
        <PasswordInput 
          label="Password" 
          placeholder="Your password" 
          required mt="md" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
        />

        <Button fullWidth mt="xl" onClick={handleSubmit}>
          Sign in
        </Button>
      </Paper>
    </Container>
  )
}

export default Login